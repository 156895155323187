<template>
  <div>
    <el-tabs
      v-model="activeName"
      type="card"
      @tab-click="handleClick"
      :before-leave="k"
    >
      <el-tab-pane
        label="包裹预报"
        name="first"
      >
        <div class="M_H topBtn">
          <el-button
            type="warning"
            size="mini"
            @click="saveData"
          >提 交</el-button>
          <el-button
            size="mini"
            plain
            @click="resetData('newForm')"
          >重 置</el-button>
        </div>
        <div class="mainBox">
          <div class="newForm">
            <!-- 选项 (货猫不显示) -->
            <!-- <div class="M_H radiosBox" v-if="NoHuoMao && pageStyle != 'edit'">
              <el-checkbox v-model="checked1" :label="1">直接转运</el-checkbox>
              <el-checkbox v-if="checked1" v-model="checked2" :label="2"
                >需要上门取件</el-checkbox
              >
            </div> -->
            <el-form
              ref="newForm"
              :model="newForm"
              :rules="newFormRules"
              label-width="100px"
            >
              <!-- 基础信息 -->
              <div class="M">
                <div class="inlineDiv">
                  <div class="queryItem1">
                    <el-form-item
                      label="快递单号:"
                      prop="courierNumber"
                    >
                      <el-input
                        v-model="newForm.courierNumber"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="queryItem1">
                    <el-form-item
                      label="快递公司:"
                      prop="expressName"
                    >
                      <el-select
                        v-model="newForm.expressName"
                        placeholder="请选择快递公司"
                        clearable
                        remote
                        filterable
                        :remote-method="
                          (e) => {
                            remoteMethod1(e);
                          }
                        "
                        @focus="
                          (e) => {
                            focusMethod1();
                          }
                        "
                        @clear="remoteMethod1('')"
                      >
                        <el-option
                          v-for="(item, index) in expressList"
                          :label="item.expressName"
                          :value="item.expressName"
                          :key="index"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <!-- <div class="queryItem1" @click="changeVip">
                  <el-form-item label="会员号:" prop="memberId">
                    <el-input
                      placeholder="自动填充"
                      v-model="newForm.memberId"
                    ></el-input>
                  </el-form-item>
                </div> -->
                  <div class="queryItem1">
                    <el-form-item
                      label="会员号:"
                      prop="memberId"
                    >
                      <el-select
                        class="queryInput"
                        @change="huiayu"
                        v-model="newForm.memberId"
                        placeholder="请选择会员"
                        clearable
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="getMembers"
                        value-key="memberId"
                      >
                        <el-option
                          v-for="(item, index) in huiyuanList"
                          :label="item.memberId"
                          :value="item.memberId"
                          :key="index"
                        >
                          {{ item.memberId }} : ({{ item.nickname }})
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="inlineDiv">
                  <div class="queryItem1">
                    <el-form-item label="会员名称:">
                      <el-select
                        class="queryInput"
                        @change="huiayuName"
                        v-model="newForm.nickname"
                        placeholder="请选择会员"
                        clearable
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="getMembersName"
                        value-key="memberId"
                      >
                        <el-option
                          v-for="(item, index) in huiyuanList"
                          :label="item.nickname"
                          :value="item.nickname"
                          :key="index"
                        >
                          {{ item.nickname }}
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>

                  <div class="queryItem1">
                    <el-form-item
                      label="货物品类:"
                      prop="itemCategoryCode"
                    >
                      <el-select
                        v-model="newForm.itemCategoryCode"
                        placeholder="请选择"
                        filterable
                        clearable
                      >
                        <el-option
                          v-for="(item, index) in qudaoList"
                          :label="item.showName"
                          :value="item.itemCategoryCode"
                          :key="index"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="queryItem1">
                    <el-form-item
                      label="仓库:"
                      prop="storageId"
                    >
                      <el-select
                        filterable
                        clearable
                        v-model="newForm.storageId"
                        placeholder="请选择集运仓库"
                      >
                        <el-option
                          v-for="(item, index) in cangkuList"
                          :label="item.storageName"
                          :value="item.id"
                          :key="index"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="inlineDiv">
                  <div class="queryItem1">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="输入团号则代表拼团"
                      placement="top"
                    >
                      <el-form-item
                        label="团号:"
                        prop="teamCode"
                      >
                        <el-input
                          clearable
                          v-model="newForm.teamCode"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </el-tooltip>
                  </div>
                  <div
                    class="queryItem1"
                    style="width: 62%"
                  >
                    <el-form-item
                      label="备注:"
                      prop="comment"
                    >
                      <el-input
                        clearable
                        type="textarea"
                        rows="1"
                        v-model="newForm.comment"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <!-- 2 -->
              <div class="M">
                <el-form-item
                  label=" "
                  label-width="20px"
                  style="margin-bottom: 10px"
                >
                  <el-row>
                    <el-col :span="3">
                      <el-form-item prop="isPrecious">
                        <el-checkbox v-model="newForm.isPrecious">是否贵重物品</el-checkbox>
                      </el-form-item>
                    </el-col>
                    <el-col :span="3">
                      <el-form-item prop="loanStatus">
                        <el-checkbox v-model="newForm.loanStatus">是否垫付快递费</el-checkbox>
                      </el-form-item>
                    </el-col>
                    <el-col :span="3">
                      <el-form-item prop="isCheck">
                        <el-checkbox v-model="newForm.isCheck">是否验货</el-checkbox>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form-item>
                <!-- 长宽高 (货猫不显示) -->
                <div
                  class="inlineDiv"
                  v-if="NoHuoMao && pageStyle != 'edit'"
                >
                  <div class="queryItem1">
                    <el-form-item
                      label="长度(cm):"
                      prop="length"
                    >
                      <el-input
                        type="number"
                        v-model="newForm.length"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="queryItem1">
                    <el-form-item
                      label="宽度(cm):"
                      prop="width"
                    >
                      <el-input
                        type="number"
                        v-model="newForm.width"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="queryItem1">
                    <el-form-item
                      label="高度(cm):"
                      prop="height"
                    >
                      <el-input
                        type="number"
                        v-model="newForm.height"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <!-- 件数重量 (货猫不显示) -->
                <div
                  class="inlineDiv"
                  v-if="NoHuoMao && pageStyle != 'edit'"
                >
                  <div class="queryItem1">
                    <el-form-item
                      label="件数:"
                      prop="boxCount"
                    >
                      <el-input
                        type="number"
                        v-model="newForm.boxCount"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="queryItem1">
                    <el-form-item
                      label="重量 (kg):"
                      prop="packageWeight"
                    >
                      <el-input
                        type="number"
                        v-model="newForm.packageWeight"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div v-if="newForm.isCheck">
                  <div style="margin: 10px 0 4px">验货服务项</div>
                  <el-checkbox-group
                    v-model="checkList"
                    @change="changeIsCheck"
                  >
                    <el-checkbox
                      v-for="items in serviceStatusList"
                      :key="items.itemServiceId"
                      :label="items.itemServiceId"
                    >
                      {{ items.title }} ( {{ items.unitPrice }} )</el-checkbox>
                  </el-checkbox-group>
                </div>

                <!--  -->
              </div>
            </el-form>
          </div>
          <!-- 品名 -->
          <div class="M">
            <div class="M_tabel">
              <el-table
                border
                :data="newForm.itemReq"
                size="small"
                style="width: 100%"
                :header-cell-style="{ background: '#f0f0f0' }"
              >
                <el-table-column
                  prop="itemName"
                  label="品名"
                  align="center"
                  min-width="200px"
                >
                  <template slot-scope="scope">
                    <el-select
                      v-model="newForm.itemReq[scope.$index].itemNameCode"
                      filterable
                      clearable
                      size="mini"
                      allow-create
                      placeholder="请选择或输入"
                      @change="changeItem($event, scope.$index)"
                    >
                      <el-option
                        v-for="(item, index) in qudaoNameList"
                        :key="index"
                        :value="item.itemNameCode"
                        :label="item.showName"
                      >
                      </el-option>
                    </el-select>
                    <el-input
                      class="myInputs"
                      v-if="!newForm.itemReq[scope.$index].itemNameCode"
                      v-model="newForm.itemReq[scope.$index].zdyName"
                      placeholder="自定义品名"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="quantity"
                  min-width="100px"
                  align="center"
                  label="单价"
                >
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      placeholder="请输入单价"
                      v-model="newForm.itemReq[scope.$index].unitPrice"
                    >
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="quantity"
                  min-width="100px"
                  align="center"
                  label="数量"
                >
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      type="number"
                      placeholder="请输入数量"
                      v-model="newForm.itemReq[scope.$index].quantity"
                    >
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="length"
                  min-width="100px"
                  align="center"
                  label="长/cm"
                >
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      type="number"
                      placeholder="请输入数量"
                      v-model="newForm.itemReq[scope.$index].length"
                    >
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="wide"
                  min-width="100px"
                  align="center"
                  label="宽/cm"
                >
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      type="number"
                      placeholder="请输入数量"
                      v-model="newForm.itemReq[scope.$index].wide"
                    >
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="height"
                  min-width="100px"
                  align="center"
                  label="高/cm"
                >
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      type="number"
                      placeholder="请输入数量"
                      v-model="newForm.itemReq[scope.$index].height"
                    >
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.$index === 0">
                      <el-button
                        @click="addbtn(scope.$index)"
                        size="mini"
                      >新增</el-button>
                    </div>
                    <div v-else>
                      <el-button
                        @click="delbut(scope.$index)"
                        size="mini"
                      >删除</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- 图片(货猫不显示) -->
          <div
            class="M"
            v-if="NoHuoMao && pageStyle != 'edit'"
          >
            <el-upload
              class="upload-demo"
              :action="activeUrl"
              list-type="picture-card"
              :limit="limitCount"
              :accept="'image/*'"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :http-request="uploadSectionFile"
              :file-list="fileList"
              :class="{ hide: hideUpload }"
            >
              <i class="el-icon-plus"></i>
              <div
                class="el-upload__tip"
                slot="tip"
              >上传图片</div>
            </el-upload>
            <el-dialog
              width="60%"
              close-on-click-modal
              :visible.sync="dialogVisible"
            >
              <img
                width="80%"
                :src="dialogImageUrl"
                alt=""
              />
            </el-dialog>
          </div>
          <!-- 寄件人信息(货猫不显示)  -->
          <div
            v-if="checked1 || checked2"
            class="M newForm"
          >
            <el-form
              ref="sendAddress"
              :model="sendAddress"
              :rules="newFormRules2"
              label-width="100px"
            >
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item
                    label="寄件人:"
                    prop="fullName"
                  >
                    <el-input
                      v-model="sendAddress.fullName"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="公司名称:"
                    prop="companyName"
                  >
                    <el-input
                      v-model="sendAddress.companyName"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="联系电话:"
                    prop="phone"
                  >
                    <el-input
                      v-model="sendAddress.phone"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <!-- <div class="queryItem1">
                  <el-form-item label="联系手机:" prop="aa4">
                    <el-input
                      v-model="sendAddress.aa4"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div> -->
                <!-- <div class="queryItem1">
                  <el-form-item label="地区:" prop="selectedOptions">
                    <el-cascader
                      clearable
                      size="large"
                      :options="regionData"
                      v-model="selectedOptions"
                      @change="handleChange"
                    >
                    </el-cascader>
                  </el-form-item>
                </div> -->
                <div class="queryItem1">
                  <el-form-item
                    label="国家/地区:"
                    prop="areaCountryId"
                  >
                    <el-select
                      filterable
                      clearable
                      v-model="sendAddress.areaCountryId"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in areaCountList"
                        :key="item.areaCountryId"
                        :label="item.showName"
                        :value="item.areaCountryId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="州/省:"
                    prop="provinceName"
                  >
                    <el-input
                      v-model="sendAddress.provinceName"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="城市:"
                    prop="cityName"
                  >
                    <el-input
                      v-model="sendAddress.cityName"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item
                    label="邮编:"
                    prop="postalCode"
                  >
                    <el-input
                      v-model="sendAddress.postalCode"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
                <div
                  class="queryItem1"
                  style="width: 62%"
                >
                  <el-form-item
                    label="详细地址:"
                    prop="address"
                  >
                    <el-input
                      v-model="sendAddress.address"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <!-- 收件人信息 (货猫不显示) -->
          <div
            v-if="checked1"
            class="M newForm"
          >
            <el-form
              ref="receiveAddress"
              :model="receiveAddress"
              :rules="newFormRules3"
              label-width="100px"
            >
              <div class="inlineDiv">
                <el-button
                  style="margin: 0 10px 10px"
                  type="primary"
                  size="mini"
                  @click="changeAddress"
                >选择地址</el-button>
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item
                    label="收件人:"
                    prop="fullName"
                  >
                    <el-input
                      v-model="receiveAddress.fullName"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="公司名称:"
                    prop="companyName"
                  >
                    <el-input
                      v-model="receiveAddress.companyName"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="联系电话:"
                    prop="tel"
                  >
                    <el-input
                      v-model="receiveAddress.phone"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <!-- <div class="queryItem1">
                  <el-form-item label="联系手机:" prop="phone">
                    <el-input
                      v-model="receiveAddress.phone"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div> -->
                <div class="queryItem1">
                  <el-form-item
                    label="国家/地区:"
                    prop="areaCountryId"
                  >
                    <el-select
                      filterable
                      clearable
                      v-model="receiveAddress.areaCountryId"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in areaCountList"
                        :key="item.areaCountryId"
                        :label="item.showName"
                        :value="item.areaCountryId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="州/省:"
                    prop="provinceName"
                  >
                    <el-input
                      v-model="receiveAddress.provinceName"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="城市:"
                    prop="cityName"
                  >
                    <el-input
                      v-model="receiveAddress.cityName"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <!-- <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="区域:" prop="areaName">
                    <el-input
                      v-model="receiveAddress.areaName"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
          
              </div> -->
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item
                    label="邮编:"
                    prop="postalCode"
                  >
                    <el-input
                      v-model="receiveAddress.postalCode"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
                <div
                  class="queryItem1"
                  style="width: 62%"
                >
                  <el-form-item
                    label="详细地址:"
                    prop="address"
                  >
                    <el-input
                      v-model="receiveAddress.address"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <!-- 运输信息 (货猫不显示) -->
          <div
            v-if="checked1"
            class="M newForm"
          >
            <el-form
              ref="applyPackInforef"
              :model="applyPackInfo"
              :rules="newFormRules4"
              label-width="100px"
            >
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item
                    label="运输方式:"
                    prop="transportId"
                  >
                    <el-select
                      v-model="applyPackInfo.transportId"
                      clearable
                      @change="selectTId"
                      placeholder="请选择运输方式"
                    >
                      <el-option
                        v-for="(item, index) in transportList"
                        :label="item.transportName"
                        :key="index"
                        :value="item.channelTransportId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="渠道:"
                    prop="channelId"
                  >
                    <el-select
                      filterable
                      clearable
                      v-model="applyPackInfo.channelId"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in channelList"
                        :label="item.channelName"
                        :key="index"
                        :value="item.channelId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="付款方式:"
                    prop="paymentMethodStatus"
                  >
                    <el-select
                      filterable
                      clearable
                      v-model="applyPackInfo.paymentMethodStatus"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in payType"
                        :label="item.showName"
                        :key="item.statusVal"
                        :value="item.statusVal"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item
                    label="提货方式:"
                    prop="handoverMode"
                  >
                    <el-select
                      filterable
                      clearable
                      v-model="applyPackInfo.handoverMode"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in handoverModeList"
                        :label="item.showName"
                        :key="item.statusVal"
                        :value="item.statusVal"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="客户单号:"
                    prop="customerNumber"
                  >
                    <el-input
                      v-model="applyPackInfo.customerNumber"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <div
                  class="queryItem1"
                  style="width: 94%"
                >
                  <el-form-item
                    label="备注:"
                    prop="comment"
                  >
                    <el-input
                      v-model="applyPackInfo.comment"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <div
                  class="queryItem1"
                  style="width: 94%"
                >
                  <el-form-item label="打包服务:">
                    <el-checkbox-group v-model="checkList">
                      <el-checkbox
                        v-for="item in serveArr"
                        :label="item.itemServiceId"
                        :key="item.itemServiceId"
                      >
                        {{ item.title }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </el-tab-pane>
      <!-- 批量导入 -->
      <el-tab-pane
        label="批量导入"
        name="second"
      >
        <div class="member">
          <div class="M_H">
            <el-button
              type="warning"
              size="mini"
              @click="Submit"
            >提 交</el-button>
            <el-button
              size="mini"
              plain
              @click="Reset"
            >重 置</el-button>
          </div>
          <div class="M topbox">
            <div class="membertop">
              <el-form
                :inline="true"
                :model="form"
                class="demo-form-inline"
              >
                <el-form-item label="会员号:">
                  <el-select
                    class="inpustyle"
                    clearable
                    v-model="BatchAdd.memberId"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="remoteMethod"
                    value-key="memberId"
                  >
                    <el-option
                      v-for="(item, index) in huiyuanList"
                      :key="index"
                      :label="item.memberId"
                      :value="item.memberId"
                    >{{ item.memberId }} : ({{ item.nickname }})</el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item>
                <el-checkbox v-model="checked">导入是否默认会员号</el-checkbox>
              </el-form-item> -->
              </el-form>
            </div>
            <div class="memberbotton">
              <p>
                提示：批量录入，【输入会员号】用于指定会员号，再将信息复制到明细中；无默认会员号请直接复制会员号导入；
              </p>
            </div>
          </div>

          <div class="Clipboard">
            <div class="Clipboardtop">
              <div>快递单号</div>
              <div>品名</div>
              <div>单价</div>
              <div>数量</div>
            </div>
            <el-input
              type="textarea"
              v-model="cutting"
              :rows="14"
            ></el-input>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 会员管理 -->
    <el-dialog
      title="会员管理"
      :visible.sync="isDT"
      width="50%"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <div class="newForm">
        <div class="formS">
          <el-form
            ref="vf"
            size="mini"
            :model="vf"
            :rules="vfr"
            label-width="80px"
          >
            <el-row>
              <el-col
                :span="19"
                style="display: flex"
              >
                <div class="queryItem1">
                  <el-form-item
                    label="会员号:"
                    prop="memberId"
                  >
                    <el-input
                      clearable
                      v-model="vf.memberId"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="会员名称:"
                    prop="nickname"
                  >
                    <el-input
                      clearable
                      v-model="vf.nickname"
                    ></el-input>
                  </el-form-item>
                </div>
                <!-- <div class="queryItem1">
                    <el-form-item label="仓库:" prop="memberId">
                      <el-select
                        v-model="vf.memberId"
                        placeholder="请选择活动区域"
                      >
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                      </el-select>
                    </el-form-item>
                  </div> -->
              </el-col>
              <el-col :span="1">
                <el-form-item label-width="10px">
                  <el-button
                    type="warning"
                    @click="cxEdit"
                  >查 询</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="tableBox">
          <el-table
            :data="huiyuanList_dis"
            size="mini"
            max-height="400px"
            border
            style="width: 100%"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="50"
            >
            </el-table-column>
            <el-table-column
              prop="memberId"
              label="会员号"
              align="center"
              min-width="100"
            >
            </el-table-column>
            <el-table-column
              prop="nickname"
              label="会员名称"
              align="center"
              min-width="120"
            >
            </el-table-column>

            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              min-width="100"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  style="color: #ef7c1b"
                  size="mini"
                  @click="xz(scope.row)"
                >选 择
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <paging
            ref="pags"
            :pageNum="pageNum"
            :total="total"
            :sizeList="sizeList"
            :size="size"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          ></paging>
        </div>
      </div>
    </el-dialog>

    <!-- 会员地址 -->
    <el-dialog
      title="会员地址"
      :visible.sync="isshowAddress"
      width="60%"
      @close="handleClose_address"
    >
      <!-- 表格 -->
      <div class="displayFlex">
        <h4>当前会员: {{ newForm.nickname }} ( {{ newForm.memberId }} )</h4>
        <!-- <el-button type="primary" size="mini" @click="addAddressBtns">新建地址</el-button> -->
      </div>
      <div
        class="tableBox"
        style="width: 99%; padding: 0"
        ref="tableWrapper"
      >
        <el-table
          ref="mytable"
          @row-click="rowclick"
          :data="tableData"
          :row-class-name="tableRowClassName"
          highlight-current-row
          :header-cell-style="{ background: '#f0f0f0' }"
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            fixed="left"
            label="序号"
            type="index"
            width="60"
          >
          </el-table-column>
          <el-table-column
            fixed="left"
            type="selection"
            width="60"
          >
          </el-table-column>
          <template v-for="(item, index) in myTableHeard">
            <el-table-column
              :key="index"
              sortable
              :label="item.name"
              :prop="item.field"
              :show-overflow-tooltip="true"
              :min-width="item.width"
              :data-isTotal="item.isTotal"
              v-if="item && item.isShow"
            >
            </el-table-column>
          </template>
        </el-table>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <!-- <el-button @click="isshowAddress = false">取 消</el-button> -->
        <el-button
          type="primary"
          @click="confirmAddressBtns"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Api } from '@/assets/js/api'
import tools from '@/assets/js/tool'
import paging from '@/components/pagings.vue'

// 省市区数据
// import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  components: { paging },
  inject: ['reload'],
  data() {
    return {
      cutting: '', //批量添加要切割的字符串
      BatchAdd: {
        memberId: '',
        packageInfo: [
          {
            courierNumber: '',
            itemName: '',
            quantity: '',
            unitPrice: '',
          },
        ],
      },
      memberNickname: '',
      //    ------------ new ------------
      activeName: 'first', //  tab标识
      newForm: {
        //  包裹预报表单数据
        isPrecious: '',
        comment: '', //备注
        packageId: '',
        loanStatus: '',
        itemReq: [
          //物品数据
          {
            itemName: '', //  物品名称  必填
            itemNameCode: '', //  渠道物品名称代码
            quantity: '', //  数量  必填
            unitPrice: '', //  单价  必填
            zdyName: '', //
            length: '',
            wide: '',
            height: '',
          },
        ],
        courierNumber: '',
        expressName: '',
        memberId: '',
        teamCode: '',
        itemCategoryCode: '',
        storageId: '',
        nickname: '',
        isCheck: '',
        length: '',
        width: '',
        height: '',
        boxCount: '',
        packageWeight: '',
      },
      fileNames: [],
      newFormRules: {
        memberId: [
          { required: true, message: '请选择', trigger: ['blur', 'change'] },
        ],
        itemCategoryCode: [
          { required: true, message: '请选择', trigger: ['blur', 'change'] },
        ],
      }, //  包裹预报表单规则配置
      newFormRules1: {}, //
      newFormRules2: {}, //
      newFormRules3: {}, //
      newFormRules4: {
        transportId: [
          { required: true, message: '请选择', trigger: ['blur', 'change'] },
        ],
      }, //
      cangkuList: [], //  仓库列表
      qudaoList: [], //  渠道品类列表
      qudaoNameList: [], //  渠道品类列表
      expressList: [], //  快递公司列表
      //  会员管理
      isDT: false,
      vf: {},
      vfr: {},
      huiyuanList: [],
      huiyuanList_dis: [],
      pageNum: 1,
      total: 0,
      size: 20,
      sizeList: [10, 20, 50, 100, 200],
      isJunp: false, //  页面是否要跳转标识
      empty: false, //拼团
      Collage: false, //拼团
      form: {
        name: '',
        delivery: false,
      },
      packageId: '',

      //  上传配置
      limitCount: 10,
      fileList: [],
      activeUrl: '',
      dialogImageUrl: '',
      dialogVisible: false,
      hideUpload: false,

      pageStyle: '',

      NoHuoMao: false, //货猫的==false
      // radioStyle: 0, //直接转运
      checked1: false, //直接转运
      checked2: false, //直接转运
      // regionData: regionData, //中国,地区数据
      selectedOptions: [],
      regionFormData: {
        province: '',
        city: '',
        area: '',
      },

      // 会员弹窗部分====s
      checkList: [],
      isshowAddress: false, //地址弹窗
      myTableHeard: [
        {
          name: '公司名称',
          field: 'corporateName',
          width: '120',
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '收件人',
          field: 'fullName',
          width: '120',
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '联系电话',
          field: 'phone',
          width: '120',
          sort: 3,
          isShow: true,
          isTotal: false,
        },
        {
          name: '邮政编号',
          field: 'postalCode',
          width: '80',
          sort: 4,
          isShow: true,
          isTotal: false,
        },
        {
          name: '国家',
          field: 'chineseName',
          width: '100',
          sort: 5,
          isShow: true,
          isTotal: false,
        },
        {
          name: '州/省',
          field: 'provinceName',
          width: '100',
          sort: 6,
          isShow: true,
          isTotal: false,
        },
        {
          name: '城市',
          field: 'cityName',
          width: '100',
          sort: 7,
          isShow: true,
          isTotal: false,
        },
        {
          name: '地址',
          field: 'address',
          width: '160',
          sort: 8,
          isShow: true,
          isTotal: false,
        },
      ],
      tableData: [],
      userId: '',
      selectTableData: [],
      // 会员弹窗部分=====end

      // 收件人信息
      receiveAddress: {
        fullName: '',
        companyName: '',
        phone: '',
        areaCountryId: '',
        postalCode: '',
        provinceName: '',
        cityName: '',
        // areaName: "",
        userAddressId: '',
        address: '',
        // tel: "",
      },
      areaCountList: [],
      serveArr: [],

      // 申请打包信息，渠道之类的
      applyPackInfo: {
        channelId: '', //渠道id
        comment: '',
        customerNumber: '', //客户单号
        handoverMode: '', //提货方式
        itemServicesInfo: [],
        paymentMethodStatus: '',
        receiveAddress: {},
        transportId: '',
      },

      // 寄件人信息
      sendAddress: {
        address: '',
        areaCountryId: '',
        areaName: '',
        cityName: '',
        companyName: '',
        fullName: '',
        phone: '',
        postalCode: '',
        provinceName: '',
        userAddressId: '',
        tel: '',
      },

      channelAndTransportList: [], //  渠道&运输方式下拉数据
      transportList: [], //  运输方式下拉菜单
      channelList: [], //  渠道方式下拉菜单
      payType: [], //  付款方式下拉菜单
      handoverModeList: [], //  提货方式
      serviceStatusList: [], //验货费项
      checkList: [], //已选费项
      itemServices: [],
    }
  },
  watch: {
    checked1: {
      handler(a, b) {
        this.checked2 = false
      },
    },
    'newForm.isCheck': function (a, b) {
      console.log('aaa1', a)
      if (a) {
        this.getCategoryServiceList()
      } else {
        this.serviceStatusList = []
        this.checkList = []
        this.itemServices = []
      }
    },
  },
  created() {
    this.getVipList()

    this.myGetStorageListPage() //  获取仓库下拉列表
    this.myGetChannelItemCategoryList() //  获取渠道品类
    this.myGetItemNameList() //  获取渠道物品名称列表
    this.remoteMethod1('') //  快递公司下拉列表
    this.getAreaContryList() //  快递国家下拉列表
    this.getPackageServe() //  打包服务
    this.getChannelAndTransportList() //  获取渠道与运输方式下拉菜单

    this.myGetStatusValList('bill_pack_v1.payment_method_status', 1) //  付款方式
    this.myGetStatusValList('bill_pack_v1.handover_mode', 2) //  提货方式

    this.NoHuoMao = tools.NoHuoMao()
  },
  activated() {
    this.packageId = this.$route.query.packageId
    if (this.packageId) {
      this.pageStyle = 'edit'
      this.myGetPackageDetail() //  获取包裹详情
    }
  },
  mounted() {},
  methods: {
    // 选择的验货服务项
    changeIsCheck(e) {
      console.log(e)
      let itemServices = []
      this.serviceStatusList.forEach((item) => {
        e.forEach((item2) => {
          if (item.itemServiceId == item2) {
            let objs = {
              itemServiceId: item.itemServiceId,
              itemServiceTitle: item.title,
              unitPrice: item.unitPrice,
            }
            itemServices.push(objs)
          }
        })
      })
      this.itemServices = itemServices
    },

    // 根据类别和费项类型获取费项
    getCategoryServiceList() {
      Api.getCategoryServiceList({
        directionStatus: 'shou:direction_status',
        serviceStatus: 'yan_huo:service_status', //验货服务
      }).then((res) => {
        let result = res.data.result || []
        console.log(result)
        this.serviceStatusList = result
      })
    },

    // 付款方式 提货方式
    myGetStatusValList(status, type) {
      Api.getStatusValList({
        tableAndFieldName: status,
      }).then((res) => {
        if (type == 1) {
          //  获取付款方式
          this.payType = res.data.result || []
        }
        if (type == 2) {
          //  提货方式
          this.handoverModeList = res.data.result || []
        }
      })
    },
    getChannelAndTransportList() {
      //  获取渠道与运输方式下拉菜单
      Api.channelAndTransportList().then((res) => {
        let obj = {}
        this.transportList = res.data.result.reduce((cur, next) => {
          //  去重
          obj[next.channelTransportId]
            ? ''
            : (obj[next.channelTransportId] = true && cur.push(next))
          return cur
        }, [])
        this.channelAndTransportList = res.data.result || []
      })
    },
    selectTId(val) {
      //  选择运输方式下拉框时筛选渠道数据
      let arr = []
      console.log(val)
      this.applyPackInfo.channelId = ''
      this.channelAndTransportList.forEach((item) => {
        if (item.channelTransportId === val) {
          arr.push(item)
        }
      })
      this.channelList = arr
    },

    //  获取服务打包项
    getPackageServe() {
      Api.getPackageServeApply().then((res) => {
        if (res.data.status === 'success') {
          this.serveArr = res.data.result || []
        } else {
          this.$message.error(`${res.data.message}--获取服务打包项`)
        }
      })
    },
    // 选择地区---------开始
    handleChange(value) {
      this.getCodeToText(null, value)
    },
    getCodeToText(codeStr, codeArray) {
      if (null === codeStr && null === codeArray) {
        return null
      } else if (null === codeArray) {
        codeArray = codeStr.split(',')
      }
      this.regionFormData.province = CodeToText[codeArray[0]]
      this.regionFormData.city = CodeToText[codeArray[1]]
      this.regionFormData.area = CodeToText[codeArray[2]]

      let area =
        CodeToText[codeArray[0]] +
        '/' +
        (CodeToText[codeArray[1]] || '') +
        '/' +
        (CodeToText[codeArray[2]] || '')
      return area
    },
    // 选择地区---------结束

    //  ----------- 图片上传 start--------------
    // 上传图片
    uploadSectionFile(param) {
      const file = param.file
      const fileName = file.name
      const form = new FormData() // 根据后台需求数据格式
      form.append('file', file) // 文件对象
      form.append('fileName', fileName) // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      let signArr = [
        {
          key: 'fileName',
          val: fileName,
        },
      ]
      let sign = tools.getMD5Sign(signArr)
      form.append('sign', sign)
      this.$showLoading()
      Api.uploadFile(form)
        .then((res) => {
          this.$hideLoading()
          if (res.data.status === 'success') {
            this.fileList.push({
              name: res.data.result.fileName,
              uid: res.data.result.redirectUrl,
              url: res.data.result.url,
            })
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(() => {
          this.$hideLoading()
        })
    },

    //  图片上传事件
    handleRemove(file, fileList) {
      /* 移除图片 */
      this.fileList = fileList
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    //  ----------- 图片上传  end--------------

    // 选择品名
    changeItem(e, idx) {
      if (!e) {
        this.newForm.itemReq[idx].itemName = ''
      }
      let code = e
      this.qudaoNameList.forEach((item) => {
        if (item.itemNameCode == code) {
          this.newForm.itemReq[idx].itemName = item.showName
        }
      })
    },
    // 选择会员
    huiayu(e) {
      if (!e) {
        this.newForm.nickname = ''
        this.newForm.memberId = ''
        this.userId = ''
        this.getVipList()
        return false
      }
      this.huiyuanList.forEach((item) => {
        if (e == item.memberId) {
          this.newForm.nickname = item.nickname
          this.userId = item.userId
        }
      })
    },
    // 选择会员
    huiayuName(e) {
      if (!e) {
        this.newForm.nickname = ''
        this.newForm.memberId = ''
        this.userId = ''
        this.getVipList()
        return false
      }
      this.huiyuanList.forEach((item) => {
        if (e == item.nickname) {
          this.newForm.memberId = item.memberId
          this.userId = item.userId
        }
      })
    },

    //批量添加提交
    Submit() {
      let textarea = this.cutting
      var String = []
      String.push(textarea)

      let datsArr = String[0].split('\n') || []
      if (datsArr.length <= 0) {
        return
      }
      let flag = /\s/ //所有空白字符
      let newArr = [] //切割后的数组
      for (var i = 0; i < datsArr.length; i++) {
        if (datsArr[i]) {
          let strArr = datsArr[i].split(flag)
          let arr1 = []
          for (var z = 0; z < strArr.length; z++) {
            if (strArr[z]) {
              arr1.push(strArr[z])
            }
          }
          newArr.push(arr1)
        }
      }
      if (newArr.length == 0) {
        return
      }
      //console.log(newArr);
      let pArr = []
      for (var a = 0; a < newArr.length; a++) {
        let bArr = newArr[a] || []
        let number = bArr[2] || 0
        var obj = {
          courierNumber: bArr[0] || '',
          itemName: bArr[1] || '',
          quantity: Math.round(number * 100) / 100,
          unitPrice: bArr[3] || '',
        }
        pArr.push(obj)
      }
      console.log(pArr)
      this.BatchAdd.packageInfo = pArr || []
      Api.batchAddPackage(this.BatchAdd).then((res) => {
        if (res.data.status == 'success') {
          this.$message.success('添加成功')
          setTimeout(() => {
            this.Reset()
          }, 500)
        }
      })
    },
    //批量添加重置
    Reset() {
      this.cutting = ''
      this.BatchAdd = {
        memberId: '',
        packageInfo: [
          {
            courierNumber: '',
            itemName: '',
            quantity: '',
            unitPrice: '',
          },
        ],
      }
    },
    myGetStorageListPage() {
      //  获取仓库下拉列表
      Api.getStorageList().then((res) => {
        if (res.data.status === 'success') {
          this.cangkuList = res.data.result || []
          if (this.cangkuList.length > 0) {
            this.newForm.storageId = this.cangkuList[0].id
          }
        } else {
          this.$message.error(`${res.data.message}--仓库下拉列表API`)
        }
      })
    },
    myGetChannelItemCategoryList() {
      //  获取渠道品类
      Api.getItemCategoryListNoPage().then((res) => {
        if (res.data.status === 'success') {
          this.qudaoList = res.data.result
        } else {
          this.$message.error(`${res.data.message}--渠道品类列表API`)
        }
      })
    },
    // 获取货物品名
    myGetItemNameList() {
      Api.getItemNameList({
        pageStart: 1,
        pageTotal: 100,
        languageCode: tools.getLanguageCode(),
        itemNameCode: '',
      }).then((res) => {
        if (res.data.status === 'success') {
          this.qudaoNameList = res.data.result.data || []
        } else {
          this.$message.error(`${res.data.message}--渠道物品名称列表API`)
        }
      })
    },

    focusMethod1() {
      if (this['expressList'].length == 0) {
        this.remoteMethod1('')
      }
    },
    remoteMethod1(e) {
      //  快递公司下拉列表
      Api.expressChooseList({
        expressName: e,
      }).then((res) => {
        if (res.data.status === 'success') {
          this.expressList = res.data.result
        } else {
          this.$message.error(`${res.data.message}--快递公司下拉列表`)
        }
      })
    },
    /**********************会员地址----------------- */
    // 获取国家地区列表
    getAreaContryList() {
      Api.getAreaContryList_q().then((res) => {
        let areaCountList = res.data.result || []
        var showArr = []
        //暂时不做中英文切换
        for (var i = 0; i < areaCountList.length; i++) {
          showArr.push({
            areaCountryId: areaCountList[i].areaCountryId,
            numberCode: areaCountList[i].numberCode,
            showName: areaCountList[i].chineseName,
          })
        }
        this.areaCountList = showArr
      })
    },
    getData() {
      let that = this
      Api.userAddressList({ userId: this.userId }).then((res) => {
        this.tableData = res.data.result || []
        this.total = this.tableData.length || 0
        that.$nextTick(() => {
          that.$refs.mytable.doLayout()
        })
      })
    },
    //分页
    handleSizeChange(val) {
      this.size = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getData()
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row'
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e
      ////console.log(e);
    },
    // 选择地址
    changeAddress() {
      if (this.userId) {
        this.isshowAddress = true
        this.getData()
      } else {
        this.$message.warning('请先选择会员')
      }
    },
    // 确认地址
    confirmAddressBtns() {
      if (this.selectTableData.length == 0) {
        this.$message.warning('请选择一条地址')
        return
      }
      if (this.selectTableData.length > 1) {
        this.$message.warning('只能选择一条地址')
        return
      }
      let datas = this.selectTableData[0]
      this.receiveAddress.fullName = datas.fullName || ''
      this.receiveAddress.companyName = datas.corporateName || ''
      this.receiveAddress.phone = datas.phone || ''
      this.receiveAddress.areaCountryId = datas.areaCountryId || ''
      this.receiveAddress.postalCode = datas.postalCode || ''
      this.receiveAddress.provinceName = datas.provinceName || ''
      this.receiveAddress.cityName = datas.cityName || ''
      // this.receiveAddress.areaName = "";
      this.receiveAddress.userAddressId = datas.userAddressId || ''
      this.receiveAddress.address = datas.address || ''

      this.isshowAddress = false
    },
    handleClose_address() {},

    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row)
      // @row-click="rowclick"
    },

    /**********************会员地址----------------- */

    saveData() {
      // 不是货猫的
      if (this.NoHuoMao && this.pageStyle !== 'edit') {
        let checkList = this.checkList
        let itemServicesInfo = []
        for (var a = 0; a < this.serveArr.length; a++) {
          for (var b = 0; b < checkList.length; b++) {
            if (this.serveArr[a].itemServiceId == checkList[b]) {
              let obj = {
                itemServiceId: this.serveArr[a].itemServiceId,
                itemServiceTitle: this.serveArr[a].title,
                unitPrice: this.serveArr[a].unitPrice,
              }
              itemServicesInfo.push(obj)
            }
          }
        }
        // 申请打包信息
        let applyPackInfo = {
          channelId: this.applyPackInfo.channelId,
          comment: this.applyPackInfo.comment,
          customerNumber: this.applyPackInfo.customerNumber,
          handoverMode: this.applyPackInfo.handoverMode,
          channelId: this.applyPackInfo.channelId,
          itemServicesInfo: itemServicesInfo,
          paymentMethodStatus: this.applyPackInfo.paymentMethodStatus,
          channelId: this.applyPackInfo.channelId,
          transportId: this.applyPackInfo.transportId,
          receiveAddress: this.receiveAddress,
        }

        let newForm = this.newForm
        // 是否贵重物品处理
        if (newForm.isPrecious) {
          newForm.isPrecious = 1
        } else {
          newForm.isPrecious = 0
        }
        // 处理货物品类
        let arrItes = JSON.parse(JSON.stringify(this.newForm)) || []
        for (var a = 0; a < arrItes.itemReq.length; a++) {
          if (!arrItes.itemReq[a].itemName && arrItes.itemReq[a].zdyName) {
            arrItes.itemReq[a].itemName = arrItes.itemReq[a].zdyName
            arrItes.itemReq[a].itemNameCode = ''
          }
        }
        for (var i = 0; i < arrItes.itemReq.length; i++) {
          delete arrItes.itemReq[i].zdyName
          if (arrItes.itemReq[i].itemName == '') {
            arrItes.itemReq.splice(i, 1)
            i--
          }
        }

        // 图片
        this.fileList.forEach((item) => {
          this.fileNames.push(item.name)
        })

        if (newForm.isCheck == true) {
          newForm.isCheck = 1
        } else {
          newForm.isCheck = 0
        }

        // 包裹信息
        let packageInfo = {
          boxCount: this.newForm.boxCount || '',
          comment: this.newForm.comment || '',
          courierNumber: this.newForm.courierNumber || '',
          expressName: this.newForm.expressName || '',
          length: this.newForm.length || '',
          width: this.newForm.width || '',
          height: this.newForm.height || '',
          packageWeight: this.newForm.packageWeight || '',
          isPrecious: this.newForm.isPrecious || 0,
          itemCategoryCode: this.newForm.itemCategoryCode || '',
          memberId: this.newForm.memberId || '',
          storageId: this.newForm.storageId || '',
          teamCode: this.newForm.teamCode || '',
          itemReq: arrItes.itemReq || [],
          itemServices: this.itemServices || [],
          isCheck: newForm.isCheck || 0,
          fileNames: this.fileNames || [],
          loanStatus: newForm.loanStatus
            ? 'dian_fu::loan_status'
            : 'bu_dian_fu:loan_status',
          doorStatus: this.checked2 ? 'need_shang_men:door_status ' : '',
        }

        let paraV2 = {
          applyPackInfo: applyPackInfo,
          packageInfo: packageInfo,
          packageType: this.checked1
            ? 'apply_pack:package_type'
            : 'add_package:package_type',
          sendAddress: this.sendAddress,
        }

        paraV2.sign = tools.getSign(paraV2)
        Api.addPackageV2(paraV2)
          .then((res) => {
            // this.$message.success(res.data.message || "新建成功");

            this.$confirm('添加成功', '提示:', {
              confirmButtonText: '继续添加',
              cancelButtonText: '返回综合查询页面',
            })
              .then(() => {
                this.reload()
              })
              .catch(() => {
                this.reload()
                this.$router.push('/BusinessManagement/comprehensiveQuery')
              })
          })
          .catch(() => {})
      } else {
        // 下面是原来集运的,货猫
        //  提交&保存数据
        let newForm = JSON.parse(JSON.stringify(this.newForm)) || []
        for (var a = 0; a < newForm.itemReq.length; a++) {
          if (!newForm.itemReq[a].itemName && newForm.itemReq[a].zdyName) {
            newForm.itemReq[a].itemName = newForm.itemReq[a].zdyName
            newForm.itemReq[a].itemNameCode = ''
          }
        }
        let arrItes = newForm || []
        for (var i = 0; i < arrItes.itemReq.length; i++) {
          delete arrItes.itemReq[i].zdyName
          if (arrItes.itemReq[i].itemName == '') {
            arrItes.itemReq.splice(i, 1)
            i--
          }
        }

        this.$refs['newForm'].validate((valid) => {
          if (valid) {
            if (newForm.isPrecious == true) {
              newForm.isPrecious = 1
            } else {
              newForm.isPrecious = 0
            }
            if (newForm.isCheck == true) {
              newForm.isCheck = 1
            } else {
              newForm.isCheck = 0
            }
            if (newForm.loanStatus == true) {
              newForm.loanStatus = 'dian_fu::loan_status'
            } else {
              newForm.loanStatus = 'bu_dian_fu:loan_status'
            }
            let parm = {
              comment: newForm.comment || '',
              courierNumber: newForm.courierNumber || '',
              expressName: newForm.expressName || '',
              isPrecious: newForm.isPrecious || 0,
              isCheck: newForm.isCheck || 0,
              itemCategoryCode: newForm.itemCategoryCode,
              itemReq: arrItes.itemReq || [],
              itemServices: this.itemServices || [],
              loanStatus: newForm.loanStatus,
              memberId: newForm.memberId || '',
              packageId: newForm.packageId || '',
              storageId: newForm.storageId || '',
            }
            let sign = tools.getSign(parm)
            parm.sign = sign
            // 修改
            if (newForm.packageId) {
              Api.updatePackage(parm)
                .then((res) => {
                  if (res.data.status === 'success') {
                    this.$message.success(res.data.message)
                    this.resetData('newForm')
                    setTimeout(() => {
                      tools.closePage()
                      this.reload()
                    }, 500)
                  }
                })
                .catch(() => {
                  // if (this.newForm.itemReq.length == 0) {
                  //   this.newForm.itemReq.push({
                  //     itemName: "", //  物品名称  必填
                  //     itemNameCode: "", //  渠道物品名称代码
                  //     quantity: 0, //  数量  必填
                  //     unitPrice: "", //  单价  必填
                  //     zdyName: "", //
                  //   });
                  // }
                })
            } else {
              Api.addPackage(parm)
                .then((res) => {
                  if (res.data.status === 'success') {
                    this.$message.success(res.data.message)
                    setTimeout(() => {
                      tools.closePage()
                      this.reload()
                    }, 500)

                    if (this.isJunp) {
                      this.$router.push({
                        // path: '/BusinessManagement/Warehousing/InspectionManagement',
                      })
                    } else {
                      this.resetData('newForm')
                    }
                  } else {
                    this.$message.error(`${res.data.message}--提交API`)
                  }
                })
                .catch(() => {
                  if (this.newForm.itemReq.length == 0) {
                    this.newForm.itemReq.push({
                      itemName: '', //  物品名称  必填
                      itemNameCode: '', //  渠道物品名称代码
                      quantity: 0, //  数量  必填
                      unitPrice: '', //  单价  必填
                      zdyName: '', //
                      length: '',
                      wide: '',
                      height: '',
                    })
                  }
                })
            }
          } else {
            return false
          }
        })
      }
    },
    myGetPackageDetail() {
      //  获取包裹详情
      Api.getPackageDetail({
        packageId: this.packageId,
      }).then((res) => {
        if (res.data.status === 'success') {
          let itemReqS = [
            //物品数据
            {
              itemName: '', //  物品名称  必填
              itemNameCode: '', //  渠道物品名称代码
              quantity: '', //  数量  必填
              unitPrice: '', //  单价  必填
              zdyName: '', //  单价  必填
              length: '', //  单价  必填
              wide: '', //  单价  必填
              height: '', //  单价  必填
            },
          ]
          //回显品名
          let newForm = res.data.result || []
          let CArrList = newForm.items || []
          CArrList.forEach((item) => {
            item.wide = item.width || 0
          })
          for (var c = 0; c < CArrList.length; c++) {
            let flag = false
            for (var d = 0; d < this.qudaoNameList.length; d++) {
              if (CArrList[c].itemName == this.qudaoNameList[d].showName) {
                // 判断是列表选择还是自定义的
                CArrList[c].itemNameCode = this.qudaoNameList[d].itemNameCode
                flag = true
                break
              }
            }
            if (!flag) {
              // 自定义品名
              CArrList[c].zdyName = CArrList[c].itemName
              CArrList[c].itemName = ''
            }
          }
          if (CArrList.length == 0) {
            CArrList = itemReqS
          }

          if (newForm.itemServices && newForm.itemServices.length > 0) {
            this.newForm.isCheck = true

            this.checkList = newForm.itemServices.map((item) => {
              return item.itemServiceId
            })

            let arrs = []
            newForm.itemServices.forEach((item) => {
              let objs = {
                itemServiceId: item.itemServiceId,
                itemServiceTitle: item.title,
                unitPrice: item.unitPrice,
              }
              arrs.push(objs)
            })
            this.itemServices = arrs
          }

          this.newForm.comment = newForm.packageComment
          this.newForm.courierNumber = newForm.courierNumber
          this.newForm.expressName = newForm.expressName
          this.newForm.isPrecious = newForm.isPrecious == 1 ? true : false
          this.newForm.itemCategoryCode = newForm.itemCategoryCode
          this.newForm.loanStatus =
            newForm.loanStatus == 'bu_dian_fu:loan_status' ? false : true
          this.newForm.memberId = newForm.memberId
          this.newForm.packageId = newForm.packageId
          this.newForm.storageId = newForm.storageId
          this.newForm.teamCode = newForm.invitationCode || ''
          this.newForm.itemReq = CArrList
          this.newForm.nickname = newForm.memberNickname
          this.memberNickname = newForm.memberNickname
          //console.log(this.newForm)
        } else {
          this.$message.error(`${res.data.message}--获取包裹详情API`)
        }
      })
    },
    resetData(formName) {
      //  重置表单
      this.memberNickname = ''
      this.$refs[formName].resetFields()
      if (formName === 'newForm') {
        this.newForm.itemReq = [
          {
            itemName: '',
            itemNameCode: '',
            quantity: '',
            unitPrice: '',
            zdyName: '',
          },
        ]
      }
    },

    //   -------- 编辑会员 -----------
    changeVip() {
      this.getVipList_dia()
      //  选择会员名称
      this.isDT = true
    },
    //远程搜索(包裹预报)
    getMembers(e) {
      this.getVipList(e)
    },
    getMembersName(e) {
      console.log(e)
      this.getVipList('', e)
    },

    // 获取会员列表
    getVipList(e, n) {
      //  获取会员列表
      Api.getMemberDownList({ memberId: e || '', nickname: n || '' }).then(
        (res) => {
          if (res.data.status === 'success') {
            this.huiyuanList = res.data.result.data || []
          }
        }
      )
    },
    //远程搜索(批量导入)
    remoteMethod(e) {
      this.getVipList(e)
    },
    getVipList_dia() {
      //  获取会员列表

      Api.getMemberDownList({
        pageStart: this.pageNum,
        pageTotal: this.size,
        memberId: this.vf.memberId,
        nickname: this.vf.nickname,
      }).then((res) => {
        if (res.data.status === 'success') {
          this.huiyuanList_dis = res.data.result.data || []
          this.total = res.data.result.pageCount || 0
        }
      })
    },

    xz(data) {
      //  会员表格选择事件
      this.newForm.memberId = data.memberId
      this.memberNickname = data.nickname
      this.isDT = false
    },
    cxEdit() {
      //  查询按钮
      this.pageNum = 1
      this.getVipList_dia()
    },
    handleSizeChange(val) {
      //  分页事件
      this.size = val
      this.getVipList_dia()
    },
    handleCurrentChange(val) {
      //  分页事件
      this.pageNum = val
      this.getVipList_dia()
    },

    handleClose() {
      //  会员弹出框关闭前回调事件
      //   this.resetData("vf");
    },
    k() {
      // return false
    },

    //  删除类型
    delbut(index) {
      this.newForm.itemReq.splice(index, 1)
    },
    warehousefun(e) {
      //console.log();
    },

    addbtn(i) {
      //console.log(i);
      this.newForm.itemReq.push({
        itemName: '',
        itemNameCode: '',
        quantity: '',
        unitPrice: '',
        zdyName: '',
        length: '',
        wide: '',
        height: '',
      })
    },

    handleClick(tab, event) {
      // //console.log(tab, event);
      // return false
    },
    //输入会员号获取会员信息
    getmember() {
      let memberId = this.addReq.memberId
      Api.getMemberByMemberId({ memberId: memberId }).then((res) => {
        //console.log(res.data.result.data[0].nickname);
        this.memberList = res.data.result.data
        this.membername = res.data.result.data[0].nickname
        ////console.log(res.data.result.data[0].nickname)
      })
    },
    //弹框输入会员号获取会员信息
    getmember1() {
      let memberId = this.memberList1.memberId
      Api.getMemberByMemberId({ memberId: memberId }).then((res) => {
        this.memberList1 = res.data.result.data
        this.memberList1.membername = res.data.result.data[0].nickname
        ////console.log(res.data.result.data[0].nickname)
      })
    },
    AdvancePayment() {
      if (this.addReq.loanStatus == 'bu_dian_fu:loan_status=') {
        this.whether = '否'
      } else {
        this.whether = '是'
      }
    },
    //提交
    // submitForm(formName) {
    //   let addReq = this.addReq;
    //   if (addReq.isPrecious) {
    //     addReq.isPrecious = 1;
    //   } else {
    //     addReq.isPrecious = 0;
    //   }
    //   if (addReq.loanStatus) {
    //     addReq.loanStatus = "dian_fu::loan_status=";
    //   } else {
    //     addReq.loanStatus = "bu_dian_fu:loan_status=";
    //   }
    //   let encryption = {
    //     comment: addReq.comment,
    //     courierNumber: addReq.courierNumber,
    //     expressName: addReq.expressName,
    //     isPrecious: addReq.isPrecious,
    //     itemCategoryCode: addReq.itemCategoryCode,
    //     itemReq: addReq.itemReq,
    //     loanStatus: addReq.loanStatus,
    //     memberId: addReq.memberId,
    //     packageId: addReq.packageId,
    //     storageId: addReq.storageId,
    //     teamCode: addReq.teamCode,
    //   };

    //   let sing = tools.getSign(encryption);
    //   addReq.sign = sing;
    //   //console.log(addReq);
    //   var courierNumber = addReq.courierNumber;
    //   Api.addPackage(addReq).then((res) => {
    //     //console.log(res);
    //     this.$message.success(res.data.message || "添加成功");
    //     let that = this;
    //     setTimeout(() => {
    //       that.$router.push({
    //         path: "/BusinessManagement/packsDetails?number=" + courierNumber,
    //       });
    //     }, 1000);
    //   });
    // },
  },
}
</script>

<style scoped lang="scss">
.M {
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 4px #e0e0e0;
  padding: 20px;
  width: 99.5%;
  box-sizing: border-box;
  .M_tabel {
    /deep/ .el-input__inner:hover,
    /deep/ .el-input__inner:focus {
      border: 1px solid #f0a261 !important;
    }
    /deep/ .el-input__inner {
      text-align: center;
    }
  }
}

.formS {
  border-bottom: 1px silver solid;
  padding-bottom: 20px;
}

.tableBox {
  width: 80%;
  padding: 20px 60px;
}
.el-col-3 {
  width: 20%;
}
.member {
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
}
.membertop {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0 0 0;
}
.memberbotton {
  color: #f00;
}
.inpustyle ::v-deep {
  .el-input__inner {
    height: 30px;
    padding: 0 40px;
  }
}
.Clipboard {
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  box-sizing: border-box;
  .Clipboardtop {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
  }
}
.topbox {
  margin-top: 20px;
}
.myInputs {
  ::v-deep {
    .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
  }
}

.radiosBox {
  padding: 0 20px;
  margin-top: 20px;
}

/deep/ .el-cascader {
  line-height: 30px !important;
}

.topBtn {
  margin-top: 10px;
}

.mainBox {
  height: calc(100vh - 135px);
  overflow-y: auto;
}

.displayFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
